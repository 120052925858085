import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { PageCard, Table, theme } from "@project/shared"
import { Button } from "../../../components"
import {
  ContentWrapper,
  PrintButtonsSection,
  TableContentSection,
  DescStyled,
  TableContainerStyled,
} from "./index.styles"
import { OperationOptions } from "./OperationOptions"
import {
  WEEKDAYS,
  getHolidayClassName,
  getPaddedMonth,
} from "../../../utils/date"
import { OwnerButton } from "../../atoms"
import { fetchMonthlyActivitiesDetail } from "../../../services/monthylActivities"
import { AuthContext } from "../../../utils"
import moment from "moment"
import { Typography } from "antd"
import styled from "styled-components"

const InformationContainer = styled.div`
  margin-top: 2rem;
  table {
    border-collapse: collapse;
  }
  .ant-table-thead > tr > th {
    display: none;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid ${theme.borderColorBase};
    padding: 9px 8px;
  }
  .info-fax {
    margin-top: 24px;
    font-weight: bold;
    text-align: center;
  }
`
export const MonthlyActivitiesPrinting = () => {
  const { t } = useTranslation()
  const { facilities } = useContext(AuthContext)
  const allFacilityIds = facilities.map((facility) => facility.id)
  const router = useRouter()
  const { year, month } = router.query
  const pageFacilityIds = (router.query.facilityIds as string)
    ?.split(",")
    .map(Number)
  const [date, setDate] = useState(
    new Date(+year, +getPaddedMonth(month as string) - 2)
  )
  const [facilityIds, setFacilityIds] = useState<number[]>(
    pageFacilityIds?.length ? pageFacilityIds : allFacilityIds
  )
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [isButtonClicked, setIsButtonClicked] = useState(false)

  useEffect(() => {
    isFilterApplied &&
      isButtonClicked &&
      router
        .push({
          query: {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            facilityIds: facilityIds.join(","),
          },
        })
        .then(() => window.scrollTo({ top: 510, behavior: "smooth" }))
  }, [date, facilityIds])
  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["fetchMonthlyActivitiesDetail", date, facilityIds],
    queryFn: () => fetchMonthlyActivitiesDetail(date, facilityIds),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
  })

  const programFacilities = allFacilityIds
  const generateFacilityColumns = () => {
    const columns = []
    for (const facilityId of facilityIds) {
      columns.push({
        title: facilities.find((facility) => facility?.id === facilityId)
          ?.facility_name_short,
        dataIndex: facilityId,
        key: facilityId,
        render: (_, record) => {
          const thisDate = new Date(
            +(year as string),
            +(month as string) - 1,
            record.day
          )

          const compareDate = moment({
            year: +year,
            month: +month - 1,
            date: record.day,
          })

          let isHoliday = false
          try {
            isHoliday = JSON.parse(
              response?.data?.holidays?.find(
                (h) => h?.facility_id == facilityId
              )?.dates
            )?.find((d) => moment(d).isSame(moment(thisDate)))
              ? true
              : false
          } catch (e) {
            isHoliday = false
          }
          const data = record?.arrResponse?.find((resp) => {
            return (
              resp.date.substring(0, 10) === compareDate.format("YYYY-MM-DD")
            )
          })
          const cellClass = isHoliday ? "saturday" : ""

          return (
            <div className={"program-text-wrapper " + cellClass}>
              {!isHoliday ? (
                data?.data?.map((program) => {
                  if (program.facility_id != facilityId) return
                  return (
                    <div key={record.day} className={"program-text"}>
                      <div className={"triangle"} />
                      {program?.program_text}
                    </div>
                  )
                })
              ) : (
                <div style={{ margin: "auto", color: "#000" }}>
                  {t("holiday")}
                </div>
              )}
            </div>
          )
        },
      })
    }
    return columns
  }

  const columns = [
    {
      title: t("Day"),
      dataIndex: "day",
      align: "center",
      key: "day",
    },
    {
      title: <span className={"col-title"}>{t("Day of week")}</span>,
      dataIndex: "weekday",
      key: "weekday",
      align: "center",
      render: (weekday) => (
        <div className={getHolidayClassName(weekday)}>{weekday}</div>
      ),
    },
    ...generateFacilityColumns(),
  ]

  const tableData = (() => {
    const arrData = []
    const arrResponse = []
    const respData = response?.data?.program || {}
    for (const [date, data] of Object.entries(respData)) {
      arrResponse.push({ date, data })
    }
    const dayCount = new Date(date.getFullYear(), date.getMonth(), 0).getDate()
    for (let i = 1; i <= dayCount; i++) {
      const thisDate = moment({
        year: date.getFullYear(),
        month: date.getMonth(),
        date: i,
      })
      const dateFacilities =
        arrResponse.find(
          (elem) => elem.date?.substring(0, 10) == thisDate.format("YYYY-MM-DD")
        )?.data || []
      if (Number(thisDate.format("D")) !== i) break

      arrData.push({
        arrResponse,
        day: Number(thisDate.format("D")),
        weekday: WEEKDAYS[thisDate.day()],
        facilities: dateFacilities?.map((elem) => elem.program_text),
        facilityData: dateFacilities,
      })
    }

    return arrData
  })()

  const setSearchParams = (newDate: Date, newFacilityIds: number[]) => {
    setIsButtonClicked(true)
    setDate(newDate)
    setFacilityIds(newFacilityIds)
    setIsFilterApplied(true)
  }

  const footerFacilities = Array.from(facilityIds).map(
    (facilityItem, index) => {
      const foundFacility = facilities.find(
        (facility) => facility.id === facilityItem
      )
      return (
        <React.Fragment key={index}>
          {foundFacility &&
            `${foundFacility?.facility_name_short} FAX: ${
              foundFacility.fax_no || "-"
            }`}{" "}
          {`${index !== programFacilities.size - 1 ? ", " : ""}`}
        </React.Fragment>
      )
    }
  )

  const InformationColumns = [
    {
      title: "",
      dataIndex: "facility_name",
      align: "left",
      key: "facility_name",
      width: "11%",
    },
    {
      title: "",
      dataIndex: "note",
      align: "left",
      key: "note",
    },
  ]

  const InformationDataSource = () => {
    return facilityIds?.map((id) => {
      const facilityInformationNote = response?.data?.information?.find(
        (elem) => {
          return elem?.facility_id === id
        }
      )
      return {
        facility_name: facilityInformationNote?.facility_short,
        note: facilityInformationNote?.note,
      }
    })
  }

  return (
    <ContentWrapper>
      <OperationOptions
        defaultYear={date?.getFullYear()}
        defaultMonth={date?.getMonth() + 1}
        defaultFacilities={facilityIds}
        programFacilities={Array.from(programFacilities)}
        isLoading={isLoading || isFetching}
        setSearchParams={setSearchParams}
      />

      <PageCard
        title={t("{{year}}年{{month}}月's program", {
          year: date?.getFullYear(),
          month: getPaddedMonth(date?.getMonth()),
        })}
      >
        <PrintButtonsSection>
          <OwnerButton
            typeOf={"secondary"}
            text={t("Back")}
            onClick={() => router.push("/monthly-activities")}
          />
          <OwnerButton
            text={t("Bulk print")}
            icon={"print"}
            onClick={window.print}
          />
          <OwnerButton
            text={t("Application form printing")}
            icon={"print"}
            onClick={() => {
              router.push({
                pathname: `/monthly-activities/application-form`,
                query:
                  facilityIds?.length > 0
                    ? {
                        year: date.getFullYear(),
                        month: date.getMonth(),
                        facilityIds: facilityIds?.slice(0, 3)?.join(","),
                      }
                    : {
                        year: date.getFullYear(),
                        month: date.getMonth(),
                      },
              })
            }}
          />
        </PrintButtonsSection>

        <TableContentSection>
          <DescStyled>
            <div className={"pagination-buttons-container"}>
              <Button
                className={"first-btn"}
                icon={"left-arrow"}
                text={t("Last month")}
                borderColor={"#D2D1D1"}
                onClick={() => {
                  setIsButtonClicked(false)
                  setIsFilterApplied(true)
                  setDate(
                    (prev) => new Date(prev.getFullYear(), prev.getMonth() - 1)
                  )
                }}
              />
              <Button
                className={"first-btn"}
                text={t("This month")}
                backgroundColor={"rgba(7, 130, 200, 0.2)"}
                onClick={() => {
                  setIsButtonClicked(false)
                  setIsFilterApplied(true)
                  setDate(new Date())
                }}
              />
              <Button
                className={"first-btn"}
                icon={"right-arrow"}
                iconPosition={"right"}
                text={t("Next month")}
                borderColor={"#D2D1D1"}
                onClick={() => {
                  setIsButtonClicked(false)
                  setIsFilterApplied(true)
                  setDate(
                    (prev) => new Date(prev.getFullYear(), prev.getMonth() + 1)
                  )
                }}
              />
            </div>
          </DescStyled>
          <TableContainerStyled>
            <Table
              loading={isLoading}
              columns={columns as any}
              dataSource={tableData}
            />
          </TableContainerStyled>
          <InformationContainer>
            <Typography.Title level={5}>{t("Informations")}</Typography.Title>
            <Table
              loading={isLoading}
              columns={InformationColumns as any}
              dataSource={InformationDataSource()}
            />
            <div className={"info-fax"}>{footerFacilities}</div>
          </InformationContainer>
        </TableContentSection>
      </PageCard>
    </ContentWrapper>
  )
}

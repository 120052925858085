// packages
import cuid from "cuid"
import { t } from "i18next"
import wareki from "wareki"

// types
import { IndividualTableBlockProps, MockDataType } from "../types"

// services-types
import { ChildOutputPrintDetailResponse } from "../../../../services/NHIFbilling"

// constants
import { ALL_SERVICE_USE_OPTIONS } from "../../../../constants/monitoring"

export const tableDataMapper: (
  data: MockDataType[]
) => IndividualTableBlockProps[] = (data) => {
  return data.map((item) => {
    const serviceType =
      item?.serviceContent === "After school day service"
        ? "放課後等デイサービス事業"
        : "児童発達支援事業"
    return {
      id: item.id + "",
      address: item.address,
      parentName: item.parentName,
      cityName: item.cityName,
      issuedDate: item.issuedDate,
      receiptSerialNo: item.receiptSerialNo,
      facilityOfficialName: item.facilityOfficialName,
      child_name: item.child_name,
      data: [
        {
          key: item.id + cuid(),
          id: item.id + cuid(),
          label: t("Service provision date"),
          value: item.serviceProvisionDate,
        },
        {
          key: item.id + cuid(),
          id: item.id + cuid(),
          label: t("Service content"),
          value: serviceType,
        },
        {
          key: item.id + cuid(),
          id: item.id + cuid(),
          label: t("Date of receipt"),
          value: item.receiptDate,
        },
        {
          key: item.id + cuid(),
          id: item.id + cuid(),
          label: t("Proxy receipt amount"),
          value: item.proxyReceiptAmt,
        },
        {
          key: item.id + cuid(),
          id: item.id + cuid(),
          label: t("Breakdown of amount received by proxy"),
          sub_label: t("Cost for service total cost\n(A)"),
          value: item.serviceTotalAmt,
        },
        {
          key: item.id + cuid(),
          id: item.id + cuid(),
          value: item.userChargeAmt,
          sub_label: t("User burden\n(B)"),
        },
        {
          key: item.id + cuid(),
          id: item.id + cuid(),
          value: item.nursingCareBenefitAmt,
          sub_label: t(
            "Nursing care benefits, etc. Amount received by proxy\n(A)-(B)"
          ),
        },
      ],
    }
  })
}

export const FormatFetchedResponseData: (
  data: ChildOutputPrintDetailResponse
) => MockDataType[] = (data) => {
  return data.data.map((d) => {
    const service_type = ALL_SERVICE_USE_OPTIONS.find(
      (option) => option.value === d.service_content
    )
    return {
      id: d.facility_id,
      parentName: d.parent_name,
      address: d.parent_address,
      cityName: d.provision_city,
      receiptSerialNo: `〒${d.parent_zip_code}`,
      serviceProvisionDate:
        d?.year && d?.month
          ? wareki(`${d?.year}-${d?.month?.toString()?.padStart(2, "0")}`, {
              unit: true,
            }) +
            d?.month?.toString()?.padStart(2, "0") +
            "月分"
          : "",
      facilityOfficialName: d.facility_name,
      serviceContent: service_type ? service_type.label : "---",
      proxyReceiptAmt:
        +d?.total_proxy_amount - d?.burden_max_month_without_subsidy,
      serviceTotalAmt: +d?.total_proxy_amount || 0,
      userChargeAmt: d?.burden_max_month_without_subsidy,
      nursingCareBenefitAmt:
        +d?.total_proxy_amount - d?.burden_max_month_without_subsidy,
      child_name: d?.child_name,
    }
  })
}

import React, { useCallback, useState, useEffect } from "react"
import styled from "styled-components"
import {
  DeleteConfimationModal,
  TextField,
  dynamicLangString,
  theme,
} from "@project/shared"
import { useRouter } from "next/router"
import { ActivitiesForm, OwnerButton } from "../../../components"
import { getDaysInMonth, getDateString, getDay, getDate } from "../../../utils"
import { useTranslation } from "react-i18next"
import moment from "moment"
import notification from "antd/lib/notification"
import { deleteMonthlyActivity } from "../../../services/monthlyActivities"
import { useMutation } from "react-query"
import _ from "lodash"

const Wrapper = styled.div`
  .calender-day-wrapper {
    display: grid;

    grid-template-columns: 1fr 1fr;
    border-left: 0.8px solid #d2d1d1;
    border-right: 0.8px solid #d2d1d1;
    .table-head {
      background: #f3f3f3;
      height: 70px;
    }
    .each-day-wrapper {
      display: flex;
      padding: 0px;
      .cell {
        display: flex;
        padding: 7px;
        align-items: center;
        justify-content: center;
        width: 30px;
        border: 0.5px solid #d2d1d1;
        margin: 0px;
        font-size: 16px;
        line-height: 23px;
        min-width: 48px;
        @media screen and (max-width: ${theme.breakpoints.sm}) {
          font-size: 14px;
          line-height: 14px;
          padding: 2px;
          width: 26px;
          min-width: 26px;
        }
      }
      .day-of-week-cell {
        width: 40px;
        @media screen and (max-width: ${theme.breakpoints.sm}) {
          font-size: 14px;
          line-height: 14px;
          width: 30px;
        }
      }
      .saturday {
        background: #cde6f4;
      }
      .sunday {
        background: rgba(224, 0, 0, 0.1);
      }
      .day-letter {
        min-width: 30px;
        margin: 0px;
        border: 0.5px solid #d2d1d1;
      }
      .calender-add-button {
        height: 30px;
        margin: 0px 10px;
        min-width: 60px;
        line-height: 5px;
      }
      .single-row-add-button {
        margin-bottom: 20px;
      }
      .each-activity {
        width: 100%;
      }
      .calender-cell {
        border: 0.5px solid #d2d1d1;
        flex: 1;
        padding: 0px 10px;
        flex-direction: column;
        justify-content: flex-start;
        @media only screen and (max-width: 767px) {
          padding: 10px;
        }
        .add-button-wrapper {
          margin-left: auto;
          @media only screen and (max-width: ${theme.breakpoints.sm}) {
            margin-top: 10px;
          }
        }
        &.no-padding {
          padding: 0;
        }
        .activities-items {
          width: 100%;
          padding-top: 18px;
          padding-left: 9px;
          display: flex;
          justify-content: flex-start;
          gap: 60px;
          @media (max-width: 1340px) {
            padding-top: 0px;
          }
          @media only screen and (max-width: 857px) {
            display: none;
          }
        }
        @media only screen and (max-width: ${theme.breakpoints.sm}) {
          padding: 10px 4px;
        }
      }
      .holiday-program {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        background: rgba(224, 0, 0, 0.1);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media only screen and (max-width: 1270px) {
          height: 70px;
        }
        @media only screen and (max-width: 767px) {
          padding: 10px;
        }
      }
    }
    @media (max-width: 1270px) {
      grid-template-columns: 1fr;
      .redundant {
        display: none;
      }
    }
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
  button {
    padding: 16px 30px;
    height: 40px;
  }

  @media (max-width: 600px) {
    justify-content: center;
  }
  @media (max-width: 426px) {
    gap: 0;
    button:last-child {
      margin-left: 10px;
    }
  }
`

const BottomWrapper = styled.div`
  margin-top: 40px;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
  .delete-button {
    background: ${theme.red2};
    border-color: ${theme.red2};
    color: white;
  }
  .sp {
    display: none;
    @media (max-width: 767px) {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
    }
  }
`
interface IMonthlyCalendarProps {
  dataSource: any
  holidays: Array<string>
  handleSave: any
  handleCancel: any
  programCategoriesData: any
  isProgramCategoryLoading: boolean
  facilityName: string
  isSaving: boolean
  note?: string
}

const MontlyCalendar: React.FC<IMonthlyCalendarProps> = ({
  dataSource,
  holidays,
  handleSave,
  handleCancel,
  programCategoriesData,
  isProgramCategoryLoading,
  facilityName,
  isSaving,
  note,
}) => {
  const [dates, setDates] = useState<any>()
  const { query, push } = useRouter()

  const year = +query.year
  const month = +query.month
  const facility_id = query.facility_id
  const { t } = useTranslation()

  const eachActivitiesInformation = {
    facility_id: +facility_id,
    program_id: undefined,
    program_category_id: 0,
    program_text: "",
    uid: `${Math.random()}`,
  }

  useEffect(() => {
    const timeZoneDefault =
      dataSource && Object.keys(dataSource)?.[0]
        ? Object.keys(dataSource)?.[0].substring(
            10,
            Object.keys(dataSource)?.[0].length
          )
        : "T00:00:00Z"
    const _dates = getDaysInMonth(
      year,
      month - 1,
      eachActivitiesInformation,
      timeZoneDefault
    )
    setDates(_dates)
  }, [year, month, dataSource])

  const [bottomNote, setBottomNote] = useState(note || "")
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  useEffect(() => {
    if (Object.keys(dataSource || {}).length) {
      Object.keys(dataSource).forEach((data) => {
        setDates((prevState) => ({
          ...prevState,
          [data]: dataSource[data],
        }))
      })
    }
  }, [dataSource])

  const handleProgramTextChange = (dateId, uid, fieldName, value) => {
    const _updatedData = dates[dateId].map((activity) => {
      if (activity.uid === uid) {
        return {
          ...activity,
          [fieldName]: value,
        }
      }
      return {
        ...activity,
      }
    })
    setDates((prevState) => ({
      ...prevState,
      [dateId]: _updatedData,
    }))
  }

  const handleProgramCategoryIdChange = (dateId, uid, value) => {
    const _updatedData = dates[dateId].map((activity) => {
      if (activity.uid === uid) {
        return {
          ...activity,
          program_category_id: value.id,
          program_text: value.label,
        }
      }
      return {
        ...activity,
      }
    })
    setDates((prevState) => ({
      ...prevState,
      [dateId]: _updatedData,
    }))
  }

  const handleAddActivity = (dateId) => {
    setDates((prevState) => ({
      ...prevState,
      [dateId]: [...prevState[dateId], eachActivitiesInformation],
    }))
  }

  const handleDeleteActivity = (dateId, uid) => {
    const _updatedData = []
    dates[dateId].forEach((activity) => {
      if (activity.uid === uid) {
        if (activity.id) {
          // if has id then keep data as it needs to be sent to backend for delete
          // else no need to keep it
          _updatedData.push({
            ...activity,
            is_deleted: true,
          })
        }
      } else {
        _updatedData.push(activity)
      }
    })
    setDates((prevState) => ({
      ...prevState,
      [dateId]: _updatedData,
    }))
  }

  const checkHoliday = (day) => {
    if (holidays?.length == 0) return false
    for (let i = 0; i < holidays?.length; i++) {
      if (moment(day).isSame(holidays[i])) return true
    }
    return false
  }

  const { mutate: deleteItem, isLoading: isDeleting } = useMutation<
    any,
    any,
    any
  >(deleteMonthlyActivity, {
    onSuccess: () => {
      notification.success({
        message: dynamicLangString([
          "Monthly activity",
          "Deleted Successfully",
        ]),
      })
      push("/monthly-activities")
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator."),
      })
    },
  })

  const _render = useCallback(() => {
    return (
      <>
        <div className={"calender-day-wrapper"}>
          <div className={"each-day-wrapper table-head"}>
            <p className={"cell"}>{t("Day")}</p>
            <p className={"cell day-of-week-cell"}>{t("day")}</p>
            <div className={"cell calender-cell"}>
              <div
                style={{
                  transform: "translateX(-44px)",
                }}
              >
                {t("Activities information")}
              </div>
              <div className={"activities-items"}>
                <p>{t("Activities category")}</p>
                <p>{t("Activities items")}</p>
              </div>
            </div>
          </div>
          <div className={"each-day-wrapper redundant table-head"}>
            <p className={"cell"}>{t("Day")}</p>
            <p className={"cell day-of-week-cell"}>{t("day")}</p>
            <div className={"cell calender-cell"}>
              <div
                style={{
                  transform: "translateX(-44px)",
                }}
              >
                {t("Activities information")}
              </div>
              <div className={"activities-items"}>
                <p>{t("Activities category")}</p>
                <p>{t("Activities items")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={"calender-day-wrapper"}>
          {Object.keys(dates || {})?.map((day) => {
            const today = getDay(day)
            const isHoliday = checkHoliday(day)
            return (
              <div key={day} className={"each-day-wrapper"}>
                <p className={"cell"}>{getDate(day)}</p>
                <p
                  className={`cell ${
                    isHoliday ? "sunday" : ""
                  } day-of-week-cell`}
                >
                  {today}
                </p>
                <div
                  className={`cell calender-cell ${
                    isHoliday ? "no-padding" : ""
                  }`}
                >
                  {isHoliday ? (
                    <div className={"holiday-program"}>{t("holiday")}</div>
                  ) : (
                    <>
                      {dates[day]?.map((eachActivity: any) => {
                        return (
                          !eachActivity.is_deleted && (
                            <div
                              key={eachActivity.uid}
                              className={"each-activity"}
                            >
                              <ActivitiesForm
                                dateId={day}
                                uid={eachActivity.uid}
                                data={eachActivity}
                                programCategoriesData={programCategoriesData}
                                handleProgramTextChange={
                                  handleProgramTextChange
                                }
                                handleProgramCategoryIdChange={
                                  handleProgramCategoryIdChange
                                }
                                handleAddActivity={handleAddActivity}
                                handleDeleteActivity={handleDeleteActivity}
                                allowDelete={
                                  dates[day]?.filter(
                                    (data) => !data?.is_deleted
                                  ).length > 1
                                }
                                isProgramCategoryLoading={
                                  isProgramCategoryLoading
                                }
                              />
                            </div>
                          )
                        )
                      })}
                      <div className={"add-button-wrapper"}>
                        {dates[day].length > 1 && (
                          <OwnerButton
                            text={t("add")}
                            onClick={() => handleAddActivity(day)}
                            className={
                              "calender-add-button single-row-add-button"
                            }
                            typeOf={"secondary"}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  }, [dates, dataSource, isProgramCategoryLoading])
  return (
    <Wrapper>
      <ButtonWrapper>
        <OwnerButton
          typeOf={"secondary"}
          text={t("Cancel")}
          onClick={handleCancel}
        />
        <OwnerButton
          typeOf={"primary"}
          text={t("Save")}
          onClick={() => handleSave({ dates, bottomNote })}
          isLoading={isSaving}
        />
      </ButtonWrapper>
      {_render()}
      <BottomWrapper>
        <div className={"sp"}>
          {"・"}
          <br />
          {" ・"}
          <br /> {"続く"}
        </div>
        <p>
          {t("{{facilityName}} Message for {{date}}", {
            facilityName: facilityName,
            date: getDateString(String(year), String(month)),
          })}
        </p>
        <TextField
          type={"textarea"}
          rows={3}
          bgcolor={"white"}
          value={bottomNote}
          onChange={(e) => setBottomNote(e.target.value)}
        />
        <br />
        <div
          className={"button-wrapper"}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "1rem 0rem",
          }}
        >
          <div className={"save-cancel"} style={{ display: "flex", gap: 20 }}>
            <OwnerButton
              typeOf={"secondary"}
              text={t("Cancel")}
              onClick={handleCancel}
            />
            <OwnerButton
              typeOf={"primary"}
              text={t("Save")}
              isLoading={isSaving}
              onClick={() => handleSave({ dates, bottomNote })}
            />
          </div>
          <div>
            {!_.isEmpty(dataSource) && (
              <OwnerButton
                text={t("Delete activity setting")}
                typeOf={"primary"}
                type={"button"}
                className={"delete-button"}
                disabled={isDeleting}
                onClick={() => setOpenDeleteModal(true)}
              />
            )}
          </div>
        </div>
      </BottomWrapper>
      <DeleteConfimationModal
        open={openDeleteModal}
        title={t("Delete")}
        footer={null}
        onCancel={() => {
          setOpenDeleteModal(false)
        }}
        confirmLoading={isDeleting}
        onOk={() =>
          deleteItem({ facility_id: +facility_id, year: +year, month: +month })
        }
      />
    </Wrapper>
  )
}

export { MontlyCalendar }

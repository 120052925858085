/**
 * NOTE:
 * - R6 June [established:新設]
 */

import { t } from "i18next"
import { Version, getCurrentVersion } from "@project/shared"
import { Moment } from "moment"
import { FormFieldsProp } from "../../../../molecules/FormFields/types"

type Params = {
  name: string
}

function buildOptions(options: [string, string][]) {
  return options.map(([label, value]) => ({ label, value }))
}

/**
 * Version management: Facility Status Form
 * @param {Moment} date
 * @param {Params} params
 * @return {FormFieldsProp|null}
 */
export function getWelfareCareStaffTreatmentImprovement(
  date: Moment,
  params: Params
) {
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
    case Version.R6_APRIL:
      return null

    case Version.R6_JUNE:
      return r6June(params.name)

    default:
      return r7January(params.name)
  }
}

/**
 * --------------------------------
 * @version R7_JANUARY
 * --------------------------------
 */

function r7January(name: string): FormFieldsProp {
  return {
    // TODO: translate
    fieldLabel: "福祉・介護職員等処遇改善",
    isRowFlexed: true,
    isShown: true,
    fields: [
      {
        name,
        fieldType: {
          type: "radio",
          extraOptions: {
            option: buildOptions([
              [t("No"), "0"],
              [t("I"), "11"],
              [t("II"), "12"],
              [t("III"), "13"],
              [t("IV"), "14"],
            ]),
          },
        },
      },
    ],
  }
}

/**
 * --------------------------------
 * @version R6_JUNE
 * --------------------------------
 */

function r6June(name: string): FormFieldsProp {
  return {
    // TODO: translate
    fieldLabel: "福祉・介護職員等処遇改善",
    isRowFlexed: true,
    isShown: true,
    fields: [
      {
        name,
        fieldType: {
          type: "radio",
          extraOptions: {
            option: buildOptions([
              [t("No"), "0"],
              [t("I"), "11"],
              [t("II"), "12"],
              [t("III"), "13"],
              [t("IV"), "14"],
            ]),
          },
        },
      },
      {
        name,
        fieldType: {
          type: "radio",
          extraOptions: {
            option: buildOptions([
              [t("V(1)"), "21"],
              [t("V(2)"), "22"],
              [t("V(3)"), "23"],
              [t("V(4)"), "24"],
              [t("V(5)"), "25"],
              [t("V(6)"), "26"],
              [t("V(7)"), "27"],
              [t("V(8)"), "28"],
              [t("V(9)"), "29"],
              [t("V(10)"), "30"],
              [t("V(11)"), "31"],
              [t("V(12)"), "32"],
              [t("V(13)"), "33"],
              [t("V(14)"), "34"],
            ]),
          },
        },
      },
    ],
  }
}

/**
 * --------------------------------
 * @version BASELINE
 * @version R6_APRIL
 *
 * NONE
 * --------------------------------
 */
